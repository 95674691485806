import {
  ArticleCollectionInterface,
  ArticleType
} from "reducers/articleReducer";
import {
  getArticle,
  KeyedArticleResponseInterface
} from "services/content/contentProxy";

export enum ActionTypeKey {
  IMPORT_SECTION_ARTICLES = "IMPORT_SECTION_ARTICLES",
  ADD_STORY = "ADD_STORY",
  FETCH_ARTICLE = "FETCH_ARTICLE"
}

export interface ImportSectionArticlesInterface {
  type: ActionTypeKey.IMPORT_SECTION_ARTICLES;
  payload: ArticleCollectionInterface;
}

/** Bulk import articles to the store */
export function importSectionArticlePreviews(
  articles: KeyedArticleResponseInterface
): ImportSectionArticlesInterface {
  const updatedArticles = Object.values(articles).reduce(
    (acc, article): ArticleCollectionInterface => {
      return {
        ...acc,
        [article.id]: { ...article, hasRead: false, isFetching: false }
      };
    },
    {}
  );

  return {
    type: ActionTypeKey.IMPORT_SECTION_ARTICLES,
    payload: updatedArticles
  };
}

export interface AddStoryInterface {
  type: ActionTypeKey.ADD_STORY;
  payload: ArticleType;
}

/** Add a single article to the story */
export function addStory(storyData: ArticleType): AddStoryInterface {
  return {
    type: ActionTypeKey.ADD_STORY,
    payload: storyData
  };
}

interface FetchArticleInterface {
  type: ActionTypeKey.FETCH_ARTICLE;
  payload: {
    data: ArticleType;
    articleId: string;
  };
}

export function fetchArticle(
  articleId: string,
  articleUrl: string,
  isOffline?: boolean
): Function {
  return (dispatch: Function): void => {
    console.log(articleUrl);
    getArticle(articleUrl, isOffline).then((articleData): void => {
      dispatch({
        type: ActionTypeKey.FETCH_ARTICLE,
        payload: {
          data: articleData,
          articleId: articleId
        }
      });
    });
  };
}

export type ArticleActionsInterface =
  | AddStoryInterface
  | ImportSectionArticlesInterface
  | FetchArticleInterface;
