import { ViewModesType, ThemeTypes, AreasTypes } from "reducers/configReducer";

export enum CONFIG_ACTION_TYPES {
  IS_OFFLINE = "IS_OFFLINE",
  VIEW_MODE = "VIEW_MODE",
  SET_AREA = "SET_AREA",
  SET_ACTIVE_ARTICLE = "SET_ACTIVE_ARTICLE",
  SET_ACTIVE_SECTION = "SET_ACTIVE_SECTION",
  SET_ACTIVE_EDITION = "SET_ACTIVE_EDITION",
  TOGGLE_SIDE_DRAWER = "TOGGLE_SIDE_DRAWER",
  SET_THEME = "SET_THEME"
}

export interface SetOfflineModeInterface {
  type: CONFIG_ACTION_TYPES.IS_OFFLINE;
  payload: boolean;
}

export function setOfflineMode(isOffline: boolean): SetOfflineModeInterface {
  return {
    type: CONFIG_ACTION_TYPES.IS_OFFLINE,
    payload: isOffline
  };
}

export interface SetViewModeInterface {
  type: CONFIG_ACTION_TYPES.VIEW_MODE;
  payload: ViewModesType;
}

export function setViewMode(viewMode: ViewModesType): SetViewModeInterface {
  return {
    type: CONFIG_ACTION_TYPES.VIEW_MODE,
    payload: viewMode
  };
}

export interface SetAreaActionInterface {
  type: CONFIG_ACTION_TYPES.SET_AREA;
  payload: AreasTypes;
}

export function setArea(area: AreasTypes): SetAreaActionInterface {
  return {
    type: CONFIG_ACTION_TYPES.SET_AREA,
    payload: area
  };
}

export interface SetActiveArticleInterface {
  type: CONFIG_ACTION_TYPES.SET_ACTIVE_ARTICLE;
  payload: string;
}

export function setActiveArticle(articleId: string): SetActiveArticleInterface {
  return {
    type: CONFIG_ACTION_TYPES.SET_ACTIVE_ARTICLE,
    payload: articleId
  };
}

export interface SetActiveSectionInterface {
  type: CONFIG_ACTION_TYPES.SET_ACTIVE_SECTION;
  payload: string;
}

export function setActiveSection(sectionId: string): SetActiveSectionInterface {
  return {
    type: CONFIG_ACTION_TYPES.SET_ACTIVE_SECTION,
    payload: sectionId
  };
}

export interface SetActiveEditionInterface {
  type: CONFIG_ACTION_TYPES.SET_ACTIVE_EDITION;
  payload: string;
}

export function setActiveEdition(editionId: string): SetActiveEditionInterface {
  return {
    type: CONFIG_ACTION_TYPES.SET_ACTIVE_EDITION,
    payload: editionId
  };
}

export interface ToggleSideDrawerOpenInterface {
  type: typeof CONFIG_ACTION_TYPES.TOGGLE_SIDE_DRAWER;
}

export function toggleSideDrawer(): ToggleSideDrawerOpenInterface {
  return {
    type: CONFIG_ACTION_TYPES.TOGGLE_SIDE_DRAWER
  };
}

export interface SetThemeInterface {
  type: CONFIG_ACTION_TYPES.SET_THEME;
  payload: ThemeTypes;
}

export function setTheme(theme: ThemeTypes): SetThemeInterface {
  return {
    type: CONFIG_ACTION_TYPES.SET_THEME,
    payload: theme
  };
}

export type ConfigActionInterfaces =
  | SetOfflineModeInterface
  | SetViewModeInterface
  | SetAreaActionInterface
  | SetActiveEditionInterface
  | SetActiveArticleInterface
  | SetActiveSectionInterface
  | ToggleSideDrawerOpenInterface
  | SetThemeInterface;
