import { importSectionArticlePreviews } from "actions/ArticleActions";
import { SECTIONS } from "data/sections";
import { getSection } from "services/content/contentProxy";

export enum EditionTypes {
  LIVE = "LIVE",
  DAILY = "DAILY"
}

export interface PackageInterface {
  id: string;
  title: string | null;
  url: string | null;
  articles: string[];
}

export interface EditionSectionInterface {
  loaded: boolean;
  fetching: boolean;
  id: string;
  packages: string[];
  articles: string[];
}

export interface EditionInterface {
  readonly edition_id: string;
  readonly published: string;
  readonly last_updated: string;
  readonly downloaded: boolean;
  readonly downloading: boolean;
  readonly download_progress: number;
  readonly sections: {
    readonly [id: string]: EditionSectionInterface;
  };
  readonly packages: { [id: string]: PackageInterface };
  readonly type: keyof typeof EditionTypes;
}

export enum EditionActionsTypes {
  FETCHING_SECTION_CONTENT = "FETCHING_SECTION_CONTENT",
  RECEIVED_SECTION_CONTENT = "RECEIVED_SECTION_CONTENT",
  ADD_PACKAGES = "ADD_PACKAGES"
}

export interface AddPackagesActionsInterface {
  type: EditionActionsTypes.ADD_PACKAGES;
  payload: {
    sectionId: string;
    editionId: string;
    packages: PackageInterface[];
  };
}

export function addPackages(
  sectionId: string,
  editionId: string,
  packages: PackageInterface[]
): AddPackagesActionsInterface {
  return {
    type: EditionActionsTypes.ADD_PACKAGES,
    payload: {
      sectionId,
      editionId,
      packages
    }
  };
}

export interface FetchingSectionContentInterface {
  type: EditionActionsTypes.FETCHING_SECTION_CONTENT;
  payload: {
    sectionId: string;
    editionId: string;
  };
}
export function fetchingSectionContent(
  sectionId: string,
  editionId: string
): FetchingSectionContentInterface {
  return {
    type: EditionActionsTypes.FETCHING_SECTION_CONTENT,
    payload: {
      sectionId: sectionId,
      editionId: editionId
    }
  };
}

export interface ReceivedSectionContentInterface {
  type: EditionActionsTypes.RECEIVED_SECTION_CONTENT;
  payload: {
    sectionId: string;
    editionId: string;
    articleIds: string[];
  };
}

export function receivedSectionContent(
  sectionId: string,
  editionId: string,
  articleIds: string[]
): ReceivedSectionContentInterface {
  return {
    type: EditionActionsTypes.RECEIVED_SECTION_CONTENT,
    payload: {
      sectionId: sectionId,
      editionId: editionId,
      articleIds
    }
  };
}

export function loadSectionContent(
  sectionId: string,
  editionId: string,
  isSpoofed?: boolean
): Function {
  return (dispatch: Function): void => {
    const section = SECTIONS[sectionId];
    if (!section) return;

    const sectionURL = `https://www.telegraph.co.uk${section.href}/`;

    dispatch(fetchingSectionContent(sectionId, editionId));
    getSection(sectionURL, isSpoofed)
      .then((jsonData): void => {
        dispatch(
          receivedSectionContent(
            sectionId,
            editionId,
            Object.keys(jsonData.articles)
          )
        );

        dispatch(importSectionArticlePreviews(jsonData.articles));
        dispatch(addPackages(sectionId, editionId, jsonData.packages));
      })
      .catch((err): void => console.error("Error fetching section data", err));
  };
}

export type EditionActionsInterfaces =
  | FetchingSectionContentInterface
  | AddPackagesActionsInterface
  | ReceivedSectionContentInterface;
