export enum VariantActions {
  UPDATE_VARIANT = "UPDATE_VARIANT",
  RESET_VARIANTS = "RESET_VARIANTS"
}

export type VariantActionsTypes = keyof typeof VariantActions;

export interface UpdateVariantActionType {
  type: VariantActionsTypes;
  payload: {
    id: string;
    selected: string | number;
  };
}
export function updateVariantAction(
  id: string,
  selected: string
): UpdateVariantActionType {
  return {
    type: VariantActions.UPDATE_VARIANT,
    payload: {
      id,
      selected
    }
  };
}

export function resetVariants(): { type: VariantActionsTypes } {
  return {
    type: VariantActions.RESET_VARIANTS
  };
}
