import React from "react";
import dlv from "dlv";
import SideDrawer from "components/SideDrawer/SideDrawer";
import { connect } from "react-redux";
import { hot } from "react-hot-loader/root";
import { ArticleCollectionView } from "views/ArticleCollectionView";
import { Header } from "components/Header/Header";
import { GlobalNavigation } from "components/GlobalNavigation/GlobalNavigation";
import { Themes, Areas, AreasTypes } from "reducers/configReducer";
import { StateInterface } from "reducers/rootReducer";
import { SECTIONS } from "data/sections";
import { ArticleType } from "reducers/articleReducer";
import "./App.css";

interface PropsInterface extends MapStateInterface {
  area: AreasTypes;
  activeArticle: string;
  activeSection: string;
  activeEdition: null | string;
  isSearchOpen: boolean;
  searchQuery: string | null;
  isSingleArticle: boolean;
}

function App(props: PropsInterface): JSX.Element {
  const {
    area,
    theme,
    sectionName,
    articles,
    articleIndex,
    activeEdition,
    activeArticle,
    activeSection,
    isSearchOpen,
    isSingleArticle
  } = props;

  return (
    <div
      className={`app base-theme ${theme === Themes.GREY && "grey-box-theme"}`}
    >
      <SideDrawer isOpen={isSearchOpen}>
        <Header
          showEditionInfo={area === Areas.EDITION}
          editionId={activeEdition}
          menuAction={(): void =>
            history.pushState(null, "", `/${area}/${activeEdition}/search`)
          }
          showButton={true}
          title={area === Areas.EDITION ? sectionName : area}
        />
        <GlobalNavigation
          area={area}
          activeEdition={activeEdition}
          activeSection={activeSection}
        />

        <ArticleCollectionView
          articles={articles}
          activeArticle={activeArticle}
          articleIndex={articleIndex}
        />
      </SideDrawer>
    </div>
  );
}

interface MapStateInterface {
  theme: string;
  sectionName: string;
  articleIndex: number;
  articles: ArticleType[];
}

function mapStateToProps(
  state: StateInterface,
  ownProps: PropsInterface
): MapStateInterface {
  const {
    activeSection,
    activeEdition,
    activeArticle,
    isSingleArticle
  } = ownProps;
  const articleIds: string[] = isSingleArticle
    ? [activeArticle]
    : dlv(
        state.editions,
        `${activeEdition}.sections.${activeSection}.articles`,
        []
      );

  const articles = articleIds
    .map((id: string): ArticleType => state.articles[id])
    .filter(Boolean);

  const index = articles.findIndex(({ id }): boolean => id === activeArticle);

  return {
    articles,
    articleIndex: index,
    theme: state.config.theme,
    sectionName: dlv(SECTIONS, `${activeSection}.text`, "")
  };
}

export default hot(connect(mapStateToProps)(App));
